import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AWS_URL } from "../../Constant";

const columns = [
  {
    field: "sno",
    headerName: "S_NO",
    width: 80,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "productName",
    headerName: "ProductName",
    width: 300,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "brand",
    headerName: "Brands",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
  {
    field: "barcode",
    headerName: "Barcode",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "quantity",
    headerName: "Quantity",
    width: 150,
    headerClassName: "custom-header",
    headerAlign: "center",
  },

  {
    field: "model_number",
    headerName: "Model_number",
    width: 200,
    headerClassName: "custom-header",
    headerAlign: "center",
  },
];

const ShortageSTocks = () => {
  const [stocksData, setStocksData] = useState([]);
  console.log("shortagesData", stocksData)

  
  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");

    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getStoreData`;
    const url = `${AWS_URL}/getStoreData`
    
    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        // console.log(data);
        const shortageStocks = data["ShortageStocks"] || [];
        const updatedShortageStocks = shortageStocks.map((stock, index) => ({
          ...stock,
          _id: stock._id || uuidv4(),
          sno: index + 1,

        }));
        setStocksData(updatedShortageStocks);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="gap-2 text-right mt-2 mr-2">
        <Link to={"/dashboard"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div className=" p-1 rounded-md">
        <div className=" text-center underline text-rose-500">
          <h1 className="font-bold text-xl ">Shortage Stocks</h1>
        </div>
      </div>

      <div
        style={{ height: 500, width: "100%", overflow: "auto" }}
        className="p-4"
      >
        <DataGrid
          sx={{
            ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
            boxShadow: 2,
            border: 2,

            borderColor: "primary.light",
            "& .custom-header": {
              // fontSize: "h7.fontSize",
              fontWeight: "bold",
              fontSize: 16,
              color: "blue",
            },
            "& .MuiDataGrid-root, .MuiDataGrid-root ": {
              backgroundColor: "primary.main",
              color: "black",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          rows={stocksData}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </>
  );
};

export default ShortageSTocks;
