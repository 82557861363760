import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { AWS_URL } from "../../Constant";

const StoreStocks = ({ storeStoksData, store_id }) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProductList, setFilterProductList] = useState([]);
  const [totalStocks, setTotalStocks] = useState([]);
  // console.log("first", totalStocks);

  useEffect(() => {
    const products = storeStoksData["Products"];

    const productStocks = products.map((pro, idx) => ({
      ...pro,
      _id: products._id || uuidv4(),
    }));
    setTotalStocks(productStocks);
    setFilterProductList(productStocks);
  }, [storeStoksData]);

  const handleDelete = (billNo) => {
    console.log(`Delete row with BillNo: ${billNo}`);
  };

  const columns = [
    {
      field: "sno",
      headerName: "ID",
      width: 50,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "productName",
      headerName: "ProductName",
      width: 200,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "brand",
      headerName: "Brands",
      width: 120,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 150,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "model_number",
      headerName: "Model_number",
      width: 150,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 80,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "cutoffprice",
      headerName: "Cutoff_Price",
      width: 110,
      headerClassName: "custom-header",
      headerAlign: "center",
    },

    {
      field: "landingCost",
      headerName: "LandingCost",
      width: 110,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "gst",
      headerName: "GST%",
      type: "number",
      width: 100,
      headerClassName: "custom-header",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "custom-header",
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          <FaEdit />
        </Button>
      ),
    },

    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "custom-header",

      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.BillNo)} // Assuming `handleDelete` is a function to delete the row
        >
          <FaTrash />
        </Button>
      ),
    },
  ];

  // console.log(editedProduct);
  // if (selectedProduct !== null) {
  //   console.log("prductid", selectedProduct._id);
  //   console.log("prductsno", selectedProduct.sno);
  // } else {
  //   console.error("selectedProduct is null");
  // }

  const handleFilterData = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const regex = new RegExp(searchQuery, "i"); // 'i' flag for case-insensitive matching

    const filterData = totalStocks.filter((product) => {
      return (
        regex.test(product.brand) ||
        regex.test(product.barcode) ||
        regex.test(product.productName)
      );
    });
    setFilterProductList(filterData);
  }, [totalStocks, searchQuery]);

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditedProduct({ ...product });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedProduct(null);
    setEditedProduct(null);
  };

  const handleSaveEdit = async () => {
    try {
      const jwtToken = Cookies.get("jwt_token");
      // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/editProduct/${store_id}/${selectedProduct._id}`;
      const url= `${AWS_URL}/editProduct/${store_id}/${selectedProduct._id}`;
      const option = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(editedProduct),
      };
      const response = await fetch(url, option);
      // console.log(response);
      if (response.ok) {
        console.log("Product updated successfully check hell");
      } else {
        console.error("Failed to update edit product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleChange = (field, value) => {
    setEditedProduct((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const prepareDownload = () => {
    const csvHeaders = columns.map((column) => column.headerName);
    const csvRows = [csvHeaders.join(",")];
    csvRows.push(...totalStocks.map((row) => Object.values(row).join(",")));
    const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "stocks.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className=" p-1 rounded-md">
        <div className="flex  justify-between mb-2">
          <div>
            <h1 className="ml-5 font-semibold text-lg">
              State: {storeStoksData.state}
            </h1>
          </div>
          <div className=" text-center underline text-rose-500">
            <h1 className="font-bold text-xl ">Stocks Details</h1>
          </div>
          <input
            type="search"
            id="search"
            className="h-8 w-60  mr-5 rounded-md border border-black bg-transparent  text-sm placeholder:text-gray-600"
            onChange={handleFilterData}
            placeholder="Search Products"
          />
        </div>
      </div>

      <div style={{ height: 480, width: "100%", overflow: "auto" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "#9F2725",
            "& .custom-header": {
              backgroundColor: "#9F2725",
              color: "white",
            },
            "& .MuiDataGrid-colCell,  .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-sortIcon ": {
              backgroundColor: "red",
            },
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
          rows={filterProductList}
          // rows={products}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 75 },
            },
          }}
          pageSizeOptions={[5, 15]}
          checkboxSelection
          disableRowSelectionOnClick
        />
        <Button
          onClick={prepareDownload}
          variant="contained"
          color="primary"
          style={{ margin: "10px" }}
        >
          Download Stocks
        </Button>
      </div>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {editedProduct && (
            <>
              <TextField
                label="Product Name"
                value={editedProduct.productName}
                onChange={(e) => handleChange("productName", e.target.value)}
                fullWidth
              />
              {/* Add text fields for other fields as needed */}
              <TextField
                label="Brand"
                value={editedProduct.brand}
                onChange={(e) => handleChange("brand", e.target.value)}
                fullWidth
              />
              <TextField
                label="Model_number"
                value={editedProduct.model_number}
                onChange={(e) => handleChange("model_number", e.target.value)}
                fullWidth
              />
              <TextField
                label="Barcode"
                value={editedProduct.barcode}
                onChange={(e) => handleChange("barcode", e.target.value)}
                fullWidth
              />
              <TextField
                label="Barcode"
                value={editedProduct.quantity}
                onChange={(e) => handleChange("quantity", e.target.value)}
                fullWidth
              />
              <TextField
                label="landingCost"
                value={editedProduct.landingCost}
                onChange={(e) => handleChange("landingCost", e.target.value)}
                fullWidth
              />
              <TextField
                label="cutoffprice"
                value={editedProduct.cutoffprice}
                onChange={(e) => handleChange("cutoffprice", e.target.value)}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoreStocks;
