import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import StoreStocks from "./StoreStocks";
import StoreSales from "./StoreSales";
import { FaTimes } from "react-icons/fa";
import AddStocks from "./AddStocks";
import Table from "./Billing/Table";
import LowStocks from "./LowStocks";
// import Table from "./Billing/Table";

const Gdigital = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id)

  const location = useLocation();
  const { store } = location.state || {};
  console.log("sefirst", store);
  const storeCode= store["RetailStoreCode"]
  console.log("gdigit storecode", storeCode)

  // const store = location.state?.store;
  const store_id = store ? store["_id"] : null;

  // const store_id= store['_id']
  const [selectedOption, setSelectedOption] = useState("stocks");

  const onBackButton = () => {
    navigate("/store");
  };

  const onOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (!store) {
      navigate("/store");
    }
  }, [store, navigate]);

  return (
    <>
      <div className="flex justify-between mt-5 pr-3 pl-2">
        <select
          className="bg-rose-700 text-lg font-bold text-white px-3 py-2 rounded-lg m-2"
          value={selectedOption}
          onChange={(e) => onOptionChange(e.target.value)}
        >
          <option value="stocks">Stocks List</option>
          <option value="sales">Sales List</option>
          <option value="addStocks">Add Stocks</option>
          <option value="billing">Billing</option>
          <option value="lowStocks">Low Stocks</option>
        </select>
        <div>
          <h1 className=" font-bold text-xl ">
            <span className=" text-blue-800 underline ">Branch Name:</span> {store.city}
          </h1>
        </div>
        <FaTimes onClick={onBackButton} />
      </div>

      {selectedOption === "stocks" && (
        <div className="pr-2 pl-2">
          <StoreStocks storeStoksData={store} store_id={store_id} />
        </div>
      )}

      {selectedOption === "sales" && (
        <div className="pr-2 pl-2">
          <StoreSales storeSalesData={store} store_id={store_id} />
        </div>
      )}

      {selectedOption === "addStocks" && (
        <div>
          <AddStocks addStocksList={store} store_id={store_id} />
        </div>
      )}
      {selectedOption === "billing" && (
        <div>
          <Table addNewBill={store} store_id={store_id} storeCode= {storeCode}/>
        </div>
      )}
      {selectedOption === "lowStocks" && (
        <div>
          <LowStocks stocksLow={store} store_id={store_id} />
        </div>
      )}
    </>
  );
};

export default Gdigital;
