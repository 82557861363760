import Cookies from "js-cookie";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AWS_URL } from "../../Constant";

const CreateStore = () => {
  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      Name: document.getElementById("Name").value,
      Address: document.getElementById("Address").value,
      Contact: document.getElementById("Contact").value,
      GST: document.getElementById("GST").value,
      state: document.getElementById("state").value,
      city: document.getElementById("city").value,
      RetailStoreCode: document.getElementById("RetailStoreCode").value,
      Products: [],
      Sales: [],
      // Products: document.getElementById("Products").value.split(",").map((item) => item.trim()),
      // Sales: document.getElementById("Sales").value.split(",").map((item) => item.trim()),
    };

    const jwtToken = Cookies.get("jwt_token");
    // const url ="https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/createStoresAccount";
    const url = `${AWS_URL}/createStoresAccount`
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log("Submitted data:", data);
    } catch (error) {
      console.error("Error while sending data to the backend:", error);
    }
  };

  const setTotalStocksOpen = () => {
    navigate("/admin");
  };

  return (
    <>
      <div className="flex flex-col h-[86vh]  justify-center rounded bg-zinc-200">
        <div className="flex justify-end p-2">
          <FaTimes
            className="text-rose-600 cursor-pointer  "
            onClick={() => setTotalStocksOpen()}
          />
        </div>
        <h1 className="text-xl text-center font-bold underline mb-5">
          Add New Store
        </h1>
        <form
          className="w-full flex flex-col items-center "
          onSubmit={handleFormSubmit}
        >
          <div className="flex mb-2 mt-2 space-x-4">
            <div className="w-80">
              <label htmlFor="Name">Name</label> <br />
              <input type="text" id="Name" className="   rounded-lg w-full" />
            </div>
            <div className="w-80">
              <label htmlFor="Address">Address</label> <br />
              <input
                type="text"
                id="Address"
                className="   rounded-lg w-full"
              />
            </div>
          </div>

          <div className="flex mb-2 mt-2 space-x-4">
            <div className="w-80">
              <label htmlFor="Contact">Contact</label> <br />
              <input
                type="text"
                id="Contact"
                className="rounded-lg w-full"
              />
            </div>

            <div className="w-80">
              <label htmlFor="GST">GST</label> <br />
              <input className=" rounded-lg w-full" type="text" id="GST" />
            </div>
          </div>

          <div className="flex mb-1 space-x-4 ">
            <div className="w-80">
              <label htmlFor="state">state</label> <br />
              <input className="rounded-lg w-full" type="text" id="state" />
            </div>

            <div className="w-80">
              <label htmlFor="city">city</label> <br />
              <input className="rounded-lg w-full" type="text" id="city" />
            </div>
          </div>

          <div className="flex mb-2 space-x-4 ">
            <div className="w-80 ">
              <label htmlFor="RetailStoreCode">RetailStoreCode</label> <br />
              <input
                className="   rounded-lg w-full"
                type="text"
                id="RetailStoreCode"
              />
            </div>
          </div>

          {/* <input type="text" id="Products" />
<input type="text" id="Sales" /> */}


          <div>
            <label htmlFor="submit"></label> <br />
            <input
              className="rounded-lg mb-1 font-bold"
              type="submit"
              id="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateStore;
