import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "../Login/login.css";
import { AWS_URL } from "../../Constant";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onSubmitFailure = (errorMsg) => {
    setShowSubmitError(true);
    setErrorMsg("You have entered an incorrect username or password.");
    navigate('/') 
  };

  const onSubmitSuccess = (jwtToken) => {
    Cookies.set("jwt_token", jwtToken, { expires: 30 });
    localStorage.setItem("token",jwtToken)
    navigate("/dashboard", { state: { username } });
    // console.log(jwtToken);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userDetails = { username, password };
    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/login`;
    const url = `${AWS_URL}/login`
    const option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDetails),
    };
    try {
      const response = await fetch(url, option);
      const data = await response.json();
     
      if (response.ok === true) {
        onSubmitSuccess(data["access_token"]);
      } else {
        navigate('/login')
        onSubmitFailure();
      }
    } catch {
      onSubmitFailure();
    }
  };

  


  return (
    <div className="Login">
      <div className="login-form">
        <div className="title">Sign In</div>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="input-container">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="button-container">
            <button
              type="submit"
              className="bg-sky-600 text-white rounded-lg p-1 w-24"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
      {showSubmitError && <p className="error-message text-rose-600 font-bold">{errorMsg}</p>}
    </div>
  );
}

export default Login;
