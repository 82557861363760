import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import CustomerInfo from "./CustomerInfo";
import Invoice from "./Invoice";
import { AWS_URL } from "../../../Constant";

const Table = ({ data, info}) => {
  const date = new Date();
  const [search, setSearch] = useState("");
  // const [barcode, setBarcode] = useState("");
  const [filterData, setFilterData] = useState(data);
  const [datas, setDatas] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [submittedData, setSubmittedData] = useState([]);
  // console.log("submitdata", submittedData)
  const [invoiceBillGenrate, setInvoiceBillGenrate] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [EmpId, setEmpId] = useState("");
  const [total, setTotal] = useState([0]);
  const [subtotal, setSubTotal] = useState(0.0);
  const [totalAmtWithoutGst, setTotalAmtWithoutGst] = useState(0.0);
  const [gst, setgst] = useState(0.0);
  const [totalCalGst, setTotalGst] = useState(
    new Array(datas.length).fill(0.0)
  );
  const [discountError, setDiscountError] = useState("");
  const [netPrice, setNetPrice] = useState(0.0);
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [transactionId, setTransactionId] = useState("");
  const [error, setError] = useState({});
  const [customerForm, setCustomer] = useState({
    customerName: "",
    customerNumber: "",
    customerAddress: "",
    employeeId: "",
  });

  // console.log("datas.....'';'.", datas)

  const handleDelete = (indexToDelete) => {
    const newArray = [...datas];
    console.log("delete call hua");
    newArray.splice(indexToDelete, 1);
    setDatas(newArray);
  };

  const handleFilterData = (event) => {
    setToggle(true);
    const searchItem = event.target.value;
    setSearch(searchItem);

    const res = data.filter((item) => {
      return (
        String(item?.barcode)
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        String(item?.model_number)
          .toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        String(item?.productName)
          .toLowerCase()
          .includes(searchItem.toLowerCase())
      );
    });
    setFilterData(res);
    //setDatas(filterData)
  };

  const selectFilterData = (item) => {
    // item.quantity = 1;
    // console.log(item);
    const newData = [...datas, item];
    console.log("newData", newData)
    setDatas(newData);
    setToggle(false);
  };

  const validation = () => {
    let err = {};

    if (customerForm.customerName === "") {
      err.customerName = "Name is required";
    }
    if (customerForm.customerAddress === "") {
      err.customerName = "Address is required";
    }
    if (customerForm.customerNumber === "") {
      err.customerName = "Number is required";
    }
    if (customerForm.employeeId === "") {
      err.customerName = "Employee id is required";
    }
    setError({ ...err });
    return Object.keys(err).length === 0;
  };

  const postData = async () => {
    const requestBody = {
      customerName,
      customerAddress,
      customerNumber,
      EmpId,
      datas,
      total,
      subtotal,
      date,
      totalAmtWithoutGst,
      gst,
      paymentMode,
      transactionId,
    };
    const jwtToken = Cookies.get("jwt_token");
    console.log(JSON.stringify(requestBody) + " datas");
    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/postData`;
    const url = `${AWS_URL}/postData`

    console.log("urlcheck constant s", url)
    const option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await fetch(url, option);

      if (response.status === 200) {
        const data = await response.json();
        setSubmittedData(data);
        setInvoiceBillGenrate(true);

      }
    } catch (error) {
      console.log("error:", error); 
      alert("Something went wrong")
    }
  };

  const invoicegenrate = async () => {
    if (datas.length > 0) {
      const allMRPDiscountFilled = datas.every((dataItem) => {
        return dataItem.mrp1 > 0;
      });
  
      if (allMRPDiscountFilled) {
        const allQunatityZero = datas.every((dataItem) => dataItem.quantity > 0);
        if (allQunatityZero) {
          await postData();
          // setInvoiceBillGenrate(true);
        } else {
          alert("Quantity is zero, please check.");
        }
      } else {
        alert("Please fill in MRP for all selected products.");
      }
    } else {
      alert("Please select products before generating the invoice.");
    }
   
  };

  const onChangeHandle = (e) => {
    const formData = { ...customerForm };
    formData[e.target.name] = e.target.value;
    setCustomer(formData);
    setCustomerForm(customerForm);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setCustomerForm(customerForm);
  };

  const setCustomerForm = (customerform) => {
    setCustomerName(customerform.customerName);
    setCustomerAddress(customerform.customerAddress);
    setCustomerNumber(customerform.customerNumber);
    setEmpId(customerform.employeeId);
  };

  const handleQuantityCahnge = (index, event) => {
    const updatedDatas = [...datas];
    updatedDatas[index].quantity = parseInt(event.target.value);
    // console.log(parseInt(event.target.value) + " value");
    if (isNaN(parseInt(event.target.value))) {
      handleDelete(index);
    }
    setDatas(updatedDatas);
  };

  data.forEach((element) => {
    datas.forEach((el) => {
      if (element.barcode === el.barcode) {
        // console.log("first match", element.quantity)
        // console.log("second match", el.quantity)
      }
    });
  });

  const handleMrpValue = (index, event) => {
    const updatedDatas = [...datas];
    updatedDatas[index].mrp1 = parseFloat(event.target.value) || 0;
    setDatas(updatedDatas);
  };

  const handleSerialNumber = (index, event) => {
    const updatedDatas= [...datas]
    updatedDatas[index].serialNumber = (event.target.value)
    setDatas(updatedDatas)
  }

  const calculateValues = (item) => {
    let mrp1 = parseFloat(item.mrp1);
    let quantity = parseInt(item.quantity, 10);
    let discount = parseFloat(item.discount);
    let landingCost = parseFloat(item.landingCost);
    let gst1 = parseInt(item.gst);
    if (isNaN(quantity) || quantity === 0) {
      quantity = 1;
    }
    if (isNaN(discount)) {
      discount = 0;
    }
    if (isNaN(mrp1)) {
      mrp1 = 0;
    }
 
    let totalWithoutDiscount = mrp1 * quantity;
    let totalWithDiscount = totalWithoutDiscount - discount;
    const gstCalculation = parseFloat(
      ((totalWithDiscount / (gst1 + 100)) * 100).toFixed(2)
    );
    const totalGstAmt = parseFloat(totalWithDiscount - gstCalculation).toFixed(
      2
    );
    let netPrice = totalWithDiscount - totalGstAmt;

    let gst = parseFloat(totalGstAmt);
    if (isNaN(gst)){
      gst =0 
    }
    if (isNaN(netPrice)){
      netPrice =0 
    }
    if (isNaN(totalWithDiscount)){
      totalWithDiscount =0 
    }
    return {
      totalWithDiscount,
      gstCalculation,
      totalGstAmt,
      gst,
      netPrice,
      landingCost,
      quantity,
    };
  };

  useEffect(() => {
    const calculatedData = datas.map(calculateValues);

    const totalValues = calculatedData.map(
      ({ totalWithDiscount }) => totalWithDiscount
    );
    setTotal(totalValues);

    const gstValues = calculatedData.map(({ gst }) => gst);
    setTotalGst(gstValues);

    const netValues = calculatedData.map(({ netPrice }) => netPrice);
    setNetPrice(netValues);

    const subtotalAmt = totalValues.reduce((acc, total) => acc + total, 0);
    setSubTotal(subtotalAmt);

    const totalAmtWithoutGst = calculatedData.reduce(
      (acc, { gstCalculation }) => acc + gstCalculation,
      0
    );
    setTotalAmtWithoutGst(totalAmtWithoutGst);

    const totalGstAmt = gstValues.reduce((acc, gst) => acc + gst, 0);
    setgst(totalGstAmt);
  }, [datas]);

  const handleDiscountChange = (index, event) => {
    const updatedDatas = [...datas];
    const userDiscount = parseFloat(event.target.value) || 0;
    updatedDatas[index].discount = userDiscount;
    setDatas(updatedDatas);

    const validationItem = {
      ...updatedDatas[index], // Provide properties required for calculation
      discount: userDiscount, // Apply the user-entered discount for validation
    };
    const { totalWithDiscount, landingCost, quantity } =
      calculateValues(validationItem);
    if (totalWithDiscount <= landingCost * quantity) {
      updatedDatas[index].discount = 0;
      setDatas(updatedDatas);
      setDiscountError("Can't Proceed..!! Too Much Discount");
    } else {
      setDiscountError("");
    }
  };

  
  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
  };

  const handlePayment = () => {
    // console.log("Payment Mode:", paymentMode);
    // console.log("Transaction ID:", transactionId);
  };

  return (
    <>
      <div className="relative">
        <CustomerInfo
          onChangeHandle={onChangeHandle}
          customerForm={customerForm}
          onSubmitForm={onSubmitForm}
          error={error}
        />
        <div className="flex m-1 sm:w-3/4 mx-3 ">
          <div className="mr-2 bg-blue-700 text-white p-2 rounded-md">
            <button className="w-20">Barcode</button>
          </div>

          <div className=" p-1 rounded-md">
            <input
              type="search"
              className="h-8 w-48 flex rounded-md border border-black/30 bg-transparent  text-sm placeholder:text-gray-600"
              value={search}
              onChange={handleFilterData}
              placeholder="Search Products"
            />
          </div>
        </div>
        <div className=" w-[800px] ml-4 pr-2 pl-2 bg-gray-500 text-white rounded-md">
          {toggle
            && search !=="" && filterData &&
              filterData.map((item, index) => (
                <indexiv key={index} onClick={() => selectFilterData(item)}>
                  <div className="flex justify-between p-1">
                    <p>{item.barcode}</p>
                    <p>{item.model_number}</p>
                    <p>{item.productName}</p>
                  </div>
                </indexiv>
              ))
            }
        </div>

        <section className="mt-6 sm:ml-8 md:ml-0 flex flex-col">
          <div className=" -my-2 overflow-x-auto sm:-mx-6 ">
            <div className="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow border border-rose-500 ">
                <table className="w-full  divide-y divide-gray-200">
                  <thead className="bg-rose-600 rounded-lg">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left  text-white font-normal "
                        id="productname"
                      >
                        <span>Product Name</span>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                        id="modelNumber"
                      >
                        Model_Number
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        MRP
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        Serial Number
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        GST%
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        Discount
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        GST(Rs.)
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        NetPrice
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        Total
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md text-white font-normal "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.isArray(datas) ? (
                      datas.map((dataItem, index) => (
                        <tr key={dataItem.model_number}>
                          <td
                            className="px-4 py-3.5 text-left text-mdfont-normal "
                            id="productname"
                          >
                            {dataItem.productName}
                          </td>
                          <td
                            className="px-4 py-3.5 text-left text-mdfont-normal "
                            id="modelNumber"
                          >
                            {dataItem.model_number}
                          </td>
                          <td>
                            <input
                              type="text"
                              autofocus
                              className="rounded-lg border-gray-400 w-20"
                              value={isNaN(dataItem.mrp1) ? 0 : dataItem.mrp1}
                              onChange={(e) => {
                                handleMrpValue(index, e);
                              }}
                            />
                          </td>

                          <td>
                            <input
                              type="text"
                              autofocus
                              className="rounded-lg border-gray-400 w-36"
                              value={(dataItem.serialNumber) }
                              onChange={(e) => {
                                handleSerialNumber(index, e);
                              }}
                            />
                          </td>

                          <td className="px-4 py-3.5 text-left text-mdfont-normal ">
                            {dataItem.gst}
                          </td>
                          <td>
                            <input
                              type="number"
                              autofocus
                              className="rounded-lg border-gray-400 w-20"
                              value={dataItem.quantity}
                              onChange={(e) => {
                                handleQuantityCahnge(index, e);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              autofocus
                              className="rounded-lg border-gray-400 w-20"
                              value={isNaN(dataItem.discount) ? 0 : dataItem.discount}
                              onChange={(e) => {
                                handleDiscountChange(index, e);
                              }}
                            />
                            {discountError && (
                              <div style={{ color: "red" }}>
                                {discountError}
                              </div>
                            )}
                          </td>

                          <td className="px-4 py-3.5 text-left text-md  font-normal">
                            {isNaN(totalCalGst[index])
                              ? parseFloat(dataItem.gstAmount).toFixed(2)
                              : parseFloat(totalCalGst[index]).toFixed(2)}
                          </td>
                          <td className="px-4 py-3.5 text-left text-md  font-normal">
                            {isNaN(netPrice[index])
                              ? dataItem.mrp1 -
                                parseFloat(dataItem.gstAmount).toFixed(2)
                              : parseFloat(netPrice[index]).toFixed(2)}
                          </td>
                          <td>
                            {isNaN(total[index])
                              ? parseFloat(dataItem.mrp1).toFixed(2)
                              : parseFloat(total[index]).toFixed(2)}
                          </td>

                          <td>
                            <FaTrash
                              className="ml-8"
                              onClick={() => handleDelete(index)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <tr className="flex flex-col items-end border-lg p-2">
          <td colSpan="5"></td>

          <td>
            <strong>
              Subtotal: Rs&nbsp;{parseFloat(totalAmtWithoutGst).toFixed(2)}
            </strong>
          </td>

          <td>
            <strong>GST: Rs&nbsp;{parseFloat(gst).toFixed(2)}</strong>
          </td>
          <td>
            <strong>
              Total: Rs&nbsp;
              {parseFloat(subtotal).toFixed(2)}
            </strong>
          </td>
          <td>
            <div className="flex ">
              <label className="w-40 p-1  font-medium inline-block ">
                Payment Mode
              </label>
              <select
                className="rounded-md w-24 font-medium border border-black/30 "
                onChange={handlePaymentModeChange}
                value={paymentMode}
              >
                <option value="Cash">Cash</option>
                <option value="card">Card</option>
                <option value="upi">UPI</option>
                <option value="finance">Finance</option>
              </select>
            </div>
          </td>
        </tr>

        {paymentMode === "card" && (
          <tr className="flex justify-end mr-2 items-end">
            <td>
              <div>
                <h1 className="w-40 p-1 font-medium inline-block">
                  Transaction ID
                </h1>
                <input
                  type="text"
                  className="rounded-md w-40  font-medium border border-black/30"
                  value={transactionId}
                  onChange={handleTransactionIdChange}
                  placeholder="Enter Transaction Id"
                />
              </div>
            </td>
          </tr>
        )}

        {paymentMode === "upi" && (
          <tr className="flex justify-end mr-2 items-end">
            <td>
              <div>
                <h1 className="w-40 p-1 font-medium inline-block">UPI ID</h1>
                <input
                  type="text"
                  className="rounded-md w-40  font-medium border border-black/30"
                  value={transactionId}
                  onChange={handleTransactionIdChange}
                  placeholder="Enter UPI ID"
                />
              </div>
            </td>
          </tr>
        )}
        {paymentMode === "finance" && (
          <tr className="flex justify-end mr-2 items-end">
            <td>
              <div>
                <h1 className="w-40 p-1 font-medium inline-block">
                  Down Payment
                </h1>
                <input
                  type="number"
                  className="rounded-md w-40  font-medium border border-black/30"
                  value={transactionId}
                  onChange={handleTransactionIdChange}
                  placeholder="Enter Down payment "
                />
              </div>
            </td>
          </tr>
        )}

        <button
          className="bg-rose-600 p-2 w-30 mb-2 rounded-lg text-white font-medium"
          onClick={() => {
            // postData();
            if (!validation()) {
              alert("Add all data");
              return null;
            }
            invoicegenrate();
            handlePayment();
            // quantityCheck();
          }}
        >
          Generate Bill
        </button>

        <Invoice
          info={info}
          invoiceBillGenrate={invoiceBillGenrate}
          setInvoiceBillGenrate={setInvoiceBillGenrate}
          customerForm={customerForm}
          date={date}
          submittedData={submittedData}
          datas={datas}
          totalCalGst={totalCalGst}
          netPrice={netPrice}
          total={total}
          paymentMode={paymentMode}
          transactionId={transactionId}
          totalAmtWithoutGst={totalAmtWithoutGst}
          gst={gst}
          subtotal={subtotal}
        />
      </div>
    </>
  );
};

export default Table;
