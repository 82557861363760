import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Login/Login";
import Home from "./Components/Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";
import Stocks from "./Components/Product/Stocks";
import AdminDashboard from "./Admin/pages/AdminDashboard";
import Sales from "./Components/Sales/Sales";
import ProtectedRoutes from "./ProtectedRoutes";
import AddProduct from "./Components/Product/AddProduct";
import ShortageSTocks from "./Components/ShortageStocks/ShortageStocks";
import CreateStore from "./Admin/createAccount/CreateStrore";
import CreateUser from "./Admin/createAccount/CreateUsers";
import StoreStocks from "./Admin/pages/StoreStocks";
import Store from "./Admin/pages/Store";
import StoreSales from "./Admin/pages/StoreSales";
import Gdigital from "./Admin/pages/Gdigital";
import Filter from "./Admin/pages/Filter";
import SalesFilter from "./Admin/pages/Billing/SalesFilter";

function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/billing" element={<Home />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/product" element={<AddProduct />} />
            <Route path="/stocks" element={<Stocks />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/stocksshort" element={<ShortageSTocks />} />
            <Route path="/all_branch_stocks" element={<Filter />} />
            <Route path="/CreateStore" element={<CreateStore />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/store" element={<Store />} />
            <Route path="/storestocks" element={<StoreStocks />} />
            <Route path="/storesales" element={<StoreSales />} />
            <Route path="/all_branch_salesList" element={<SalesFilter />} />
            <Route path="/gdigital/:id" element={<Gdigital />} />
            
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
