import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AWS_URL } from "../../Constant";

const Store = () => {
  const [storeInfo, setStoreInfo] = useState();
  console.log("allinfostore", storeInfo);

  const fetchData = async () => {
    const jwtToken = Cookies.get("jwt_token");
    // const url = `https://ec2-13-235-112-81.ap-south-1.compute.amazonaws.com/getAllStoreData`;
    const url = `${AWS_URL}/getAllStoreData`

    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const response = await fetch(url, option);
      if (response.status === 200) {
        const data = await response.json();
        setStoreInfo(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleViewMore = (storesId) => {
    navigate(`/gdigital/${storesId}`);
  };

  
  return (
    <>
<div className="gap-2 text-right mt-2 mr-2">
        <Link to={"/dashboard"}>
          <button className=" mr-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  p-1 rounded-lg w-40 text-white text-lg  ">
            Dashboard
          </button>
        </Link>
      </div>

      <div className="font-medium underline">G-Digital Store's</div>
      <div className="flex flex-row flex-wrap gap-3 justify-center items-center mt-5  ">
        {storeInfo &&
          storeInfo.map((item, store) => {
            if (item) {
              return (
                <div key={store._id}>
                  <div className="flex flex-col w-[200px] rounded-lg justify-center bg-gray-200">
                    <div className="text-md mr-2 list-none font-medium  text-base">
                      <span className="text-rose-700 font-bold">Store: </span>
                      {item.city}
                    </div>
                    <Link
                      to={`/gdigital/${item._id}`}
                      state={{ store: item }}
                    >
                      <button
                        className="bg-blue-600 m-3 text-white p-1 rounded-lg w-[100px]"
                        onClick={() => handleViewMore(item._id)}
                      >
                        View More
                      </button>
                    </Link>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </>
  );
};

export default Store;
